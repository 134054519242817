<div class="c-auth-main-content c-auth-sign-in">

    <div class="auth-form-container bg-white">
        <div class="form-container">
            <div class="auth-heading-parent">
                <p class="auth-subhead">
                    Confirm account deletion 
                </p>
                <!-- <button class="cross-btn" (click)="close()">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6514 23.4898L23.1022 13.0391" stroke="black" stroke-width="2.21695"
                            stroke-linecap="round" />
                        <path d="M12.6514 13.0391L23.1021 23.4898" stroke="black" stroke-width="2.21695"
                            stroke-linecap="round" />
                    </svg>
                </button> -->
            </div>
            <!-- <p class="verify-email-text">Verify your email</p> -->

            <!-- <form [formGroup]="confirmEmailForm" (ngSubmit)="onSubmit()"> -->
            <!-- <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group mb-4">
                            <label for="email" class="auth-form-label">Email</label>
                            <input type="email" class="form-control auth-form-input auth-input-border"
                                [ngClass]="{ err: submitted && f['email'].errors }" formControlName="email"
                                placeholder="example@holiday.com" />
                            <div>
                                <span class="auth-help-block ng-invalid text-danger mb-0" *ngIf="
                      (!f['email'].valid && !f['email'].pristine) ||
                      (f['email'].errors && submitted)
                    ">
                                    <span class="small" *ngIf="submitted && f['email'].errors?.['required']">
                                        {{ "form.email.required" | translate }}
                                    </span>
                                </span>

                                <span class="auth-help-block ng-invalid text-danger mb-0"
                                    *ngIf="submitted && f['email'].errors?.['email'] && !err">
                                    <span class="small" *ngIf="
                        submitted && (f['email'].errors?.['email'] || !errorMessage)
                      ">
                                        {{ "form.email.invalid" | translate }}
                                    </span>
                                </span>
                                <span class="auth-help-block ng-invalid text-danger mb-0"
                                    *ngIf="f['email'].errors?.['incorrect']">
                                    <span class="small">
                                        {{ errorMessage }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div> -->
            <!-- <button type="submit" class="btn auth-btn-submit mb-3" [disabled]="showOTPSection">
                    <div class="d-flex align-items-center justify-content-center">
                        <p class="mb-0 flex-fill btn-text">
                            {{ "auth.forgot_password.request_otp" | translate }}
                        </p>
                    </div>
                </button> -->
            <div class="auth-help-block mb-3" *ngIf="showOTPSection">
                <span class="notification-text">
                    A verification code has been sent to your email address.
                </span>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group mb-4" [class.pe-none]="!showOTPSection">
                        <label for="inputOTP" class="auth-form-label">
                            {{ "form.otp_title" | translate }}
                        </label>
                        <app-angular-otp id="inputOTP" [setting]="settings"
                            [hasError]="otpError && otpValue.length === settings.length"
                            (valueChange)="onOTPChange($event)"
                            [class.otp-error]="otpError && otpValue.length > 0"></app-angular-otp>
                    </div>

                    <p class="mb-2 mb-md-3 auth-subtext" [ngClass]="{
                'text-danger': otpError && otpValue.length === settings.length
              }">
                        <span *ngIf="otpError && otpValue.length === settings.length;"
                            [innerHtml]="'auth.forgot_password.error_sub_text' | translate"></span>
                    </p>
                </div>
            </div>

            <button type="submit" (click)="confirmDeleteAccount()" class="btn btn-submit mb-3" [disabled]="
              !showOTPSection || otpError || otpValue.length !== settings.length || isSubmitting
            ">
                Delete Account
            </button>
            <button type="button" class="btn-cancel" (click)="close()">Cancel</button>
            <!-- </form> -->
        </div>
    </div>
</div>
