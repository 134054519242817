<div class="accordion" id="accordionExample">
    <div class="accordion-item"
        *ngFor="let faq of (faqLimit ? faqList.slice(0, faqLimit) : faqList); let accIndex = index;">
        <div class="accordion-header">
            <button class="accordion-button collapsed faq-accordion-button" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapseIndex' + accIndex" aria-expanded="false"
                [attr.aria-controls]="'accordionIndex' + accIndex">
                <span class="ms-2 ms-md-2" [innerHTML]="faq.question">
                </span>
            </button>
        </div>
        <div [id]="'collapseIndex' + accIndex" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div class="accordion-body faq-accordion-body">
                <div [innerHTML]="faq.answer"></div>
            </div>
        </div>
    </div>
</div>