<div class="payment-status mx-auto">
    <div class="card-payment-status">
        <div class="card-body">
            <div class="body-white-bg">
                <h3 class="payment-status-heading">
                    Confirm account deletion 
                </h3>
                <button class="cross-btn" (click)="close(false)">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6514 23.4898L23.1022 13.0391" stroke="black" stroke-width="2.21695"
                            stroke-linecap="round" />
                        <path d="M12.6514 13.0391L23.1021 23.4898" stroke="black" stroke-width="2.21695"
                            stroke-linecap="round" />
                    </svg>
                </button>
                <p class="payment-text">Are you sure you want to delete your account? This cannot be undone. You can
                    still use your purchased eSIMs, but you won’t be able to top up data or track your usage.</p>
                <ng-container>
                    <div class="button-row">
                        <button type="button" class="btn go-back-btn" (click)="close(false)">Go Back</button>
                        <button type="button" class="btn confirm-and-delete-btn" (click)="close(true)">Confirm and
                            Delete</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>