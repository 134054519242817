<!-- Footer V2.0 -->

<div class="footer bg-white">
    <div class="container">
        <div class="footer-top">
            <div class="row">
                <div class="col-xl-8 col-md-7">
                    <a href="">
                        <img src="/assets/images/common/holidaycom-horizontal-logo.png" alt="mdo" class="footer-logo">
                    </a>
                    <p class="footer-subhead-text">&copy; {{currentYear}} holiday.com. All rights reserved</p>
                </div>
                <div class="col-xl-4 col-md-5 d-none d-md-block">
                    <div class="d-flex align-items-center justify-content-end gap-md-4 gap-2">
                        <div class="app-img-container">
                            <a href="https://apps.apple.com/us/app/holiday-com-esim/id6738407696" target="_blank">
                                <img src="assets/images/landing-page/appstore-black.png" class="" alt="App store">
                            </a>
                        </div>
                        <div class="app-img-container">
                            <a href="https://play.google.com/store/apps/details?id=com.holiday.esim" target="_blank">
                                <img src="assets/images/landing-page/playstore-black.png" class="" alt="Google play">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-bottom">
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-4 col-xl-4 col-7">
                            <p class="footer-head-text"> {{ 'footer.useful_links' | translate }}</p>
                            <p class="footer-subhead-text">
                                <a routerLink="privacy-policy" target="_self"
                                    class="text-decoration-none footer-subhead-url">{{
                                    'header.privacy_policy' | translate }}</a>
                            </p>
                            <p class="footer-subhead-text">
                                <a routerLink="terms-and-conditions" target="_self"
                                    class="text-decoration-none footer-subhead-url">Terms & Conditions</a>
                            </p>
                            <p class="footer-subhead-text">
                                <a routerLink="compatible-devices" target="_self" rel="noopener noreferrer"
                                    class="text-decoration-none footer-subhead-url">eSIM compatible devices</a>
                            </p>

                            <p class="footer-subhead-text">
                                <a href=" https://holidayesimsupport.zendesk.com/hc/en-us" target="_self"
                                    rel="noopener noreferrer"
                                    class="text-decoration-none footer-subhead-url">Support</a>
                            </p>
                        </div>
                        <div class="col-md-4 col-xl-4 col-5">
                            <p class="footer-head-text">{{ 'footer.contact_us' | translate }}</p>
                            <p class="footer-subhead-text">
                                <a href="mailto:support@holiday.com" target="_blank" rel="noopener noreferrer"
                                    class="text-decoration-none footer-subhead-url">support@holiday.com</a>
                            </p>
                            <!-- <p class="footer-subhead-text">
                                +16466008066
                            </p> -->
                            <div class="follow-us-mob d-md-none">
                                <p class="footer-head-text">{{ 'footer.follow_us' | translate }}</p>
                                <div class="social-img-container">
                                    <a href="https://www.instagram.com/goholidaydotcom" target="_blank"
                                        rel="noopener noreferrer" class="text-decoration-none">
                                        <img class="social-img" src="/assets/images/landing-page/instagram-icon.png"
                                            alt="instagram logo">
                                    </a>

                                    <a href="https://www.facebook.com/goholidaydotcom" target="_blank"
                                        rel="noopener noreferrer" class="text-decoration-none">
                                        <img class="social-img" src="/assets/images/landing-page/facebook-icon.png"
                                            alt="facebook logo">
                                    </a>

                                    <a href="https://x.com/goholidaydotcom" target="_blank" rel="noopener noreferrer"
                                        class="text-decoration-none">
                                        <img class="social-img" src="/assets/images/landing-page/twitter-icon.png"
                                            alt="twitter logo">
                                    </a>


                                    <a href="https://www.youtube.com/@goholidaydotcom" target="_blank"
                                        rel="noopener noreferrer" class="text-decoration-none">
                                        <img class="social-img" src="/assets/images/landing-page/holiday-youtube.png"
                                            alt="Youtube logo">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-3 col-7 address-col">
                            <p class="footer-head-text"> {{ 'footer.our_address' | translate }}</p>
                            <p class="footer-subhead-text">447 Broadway, 2nd Floor Suite #1302, New York - 10013</p>
                        </div> -->
                        <div class="col-md-4 col-xl-4 col-5 social-img-container d-none d-md-block">
                            <p class="footer-head-text">{{ 'footer.follow_us' | translate }}</p>
                            <div>
                                <a href="https://www.instagram.com/goholidaydotcom" target="_blank"
                                    rel="noopener noreferrer" class="text-decoration-none">
                                    <img class="social-img" src="/assets/images/landing-page/instagram-icon.png"
                                        alt="instagram logo">
                                </a>

                                <a href="https://www.facebook.com/goholidaydotcom" target="_blank"
                                    rel="noopener noreferrer" class="text-decoration-none">
                                    <img class="social-img" src="/assets/images/landing-page/facebook-icon.png"
                                        alt="facebook logo">
                                </a>

                                <a href="https://x.com/goholidaydotcom" target="_blank" rel="noopener noreferrer"
                                    class="text-decoration-none">
                                    <img class="social-img" src="/assets/images/landing-page/twitter-icon.png"
                                        alt="twitter logo">
                                </a>

                                <a href="https://www.youtube.com/@goholidaydotcom" target="_blank"
                                    rel="noopener noreferrer" class="text-decoration-none">
                                    <img class="social-img" src="/assets/images/landing-page/holiday-youtube.png"
                                        alt="Youtube logo">
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-1">
                    <div class="usd-footer-container">
                        <p class="footer-head-text">$ USD</p>

                    </div>
                </div>
                <div class="col-sm-8 co-12 col-md-3 mt-4 mt-md-0">
                    <div class="d-flex align-items-center gap-2">
                        <div class="app-img-container d-md-none d-lg-none">
                            <a href="https://apps.apple.com/us/app/holiday-com-esim/id6738407696" target="_blank">
                                <img src="assets/images/landing-page/appstore-black.png" class="" alt="App store">
                            </a>
                        </div>
                        <div class="app-img-container d-md-none d-lg-none">
                            <a href="https://play.google.com/store/apps/details?id=com.holiday.esim" target="_blank">
                                <img src="assets/images/landing-page/playstore-black.png" class="" alt="">
                            </a>
                        </div>

                    </div>
                    <div class="col-lg-12">
                        <div class="team-behind-container">
                            <p class="team-behind-text">FROM THE TEAM BEHIND</p>
                            <div class="express-vpn-image-container">
                                <a href="https://www.expressvpn.com/" target="_blank">
                                    <img class="img-fluid" src="/assets/images/common/express-vpn.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>