<div class="g-page-title-section">

        <button type="button" class="g-back-arrow" (click)="goPreviousLocation()" *ngIf="isShow">
            <svg xmlns="http://www.w3.org/2000/svg"  fill="var(--color-black)" class="bi bi-arrow-left-short g-back-arrow-svg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
            </svg>
        </button>

    <p class="g-page-main-title" *ngIf="title" [ngClass]="titleStyle?.title || ''">
        <span *ngIf="hasAmpersand">
            <div class="d-flex justify-content-center">
                <p class="m-0"> {{title.split('&')[0] }} </p> &nbsp;
                <p class="m-0">&amp; </p> &nbsp;
                <p class="m-0"> {{ title.split('&')[1] }}</p>
            </div>
        </span>

        <span *ngIf="!hasAmpersand">
            {{ title }}
        </span>
    </p>

    <p class="g-page-sub-details text-center" *ngIf="subDetails" [ngClass]="titleStyle?.subDetails || ''">
        <!-- {{ subDetails }}   -->
        <span *ngIf="hasAmpersAndsubDetails">
            <div class="d-flex justify-content-center flex-wrap">
                <p class="m-0"> {{subDetails.split('&')[0] }} </p> &nbsp;
                <p class="m-0">&amp; </p> &nbsp;
                <p class="m-0"> {{ subDetails.split('&')[1] }}</p>
            </div>
        </span>

        <span *ngIf="!hasAmpersAndsubDetails">
            {{ subDetails }}
        </span>
    </p>


</div>
