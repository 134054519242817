import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '.';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  serverUrl = environment.serverUrl;
  customerId = environment.customerId;

  defaultCurrency: string | undefined = '';
  constructor(private http: HttpClient, private _localStorageService: LocalStorageService) { }

  // getCountries() {
  //   return this.http.get(`${this.serverUrl}/countries?customerId=${this.customerId}${(this.defaultCurrency && this.defaultCurrency != '') ?  '&currency=' + this.defaultCurrency : ''}`);
  // }

  getCountries(search?: string) {
    let url = `${this.serverUrl}/countries?customerId=${this.customerId}`;

    if (this.defaultCurrency && this.defaultCurrency !== '') {
      url += `&currency=${this.defaultCurrency}`;
    }

    if (search && search.trim()) {
      url += `&search=${search}`;  // Append search param if it's provided
    }

    return this.http.get(url);
  }

  getFAQs() {
    const lang = this._localStorageService.getItem("language");
    const reqUrl = environment?.customerId ? `?customerId=${environment?.customerId}&language=${lang}` : `?language=${lang}`;
    return this.http.get(`${this.serverUrl}/faqs`+ reqUrl);
  }

  getCurrencySettings(){
    let routeUrl = '';
    if(this._localStorageService.getToken()){
      routeUrl = `settings/currency`;
    } else {
      routeUrl = `subscribers/settings/currency?customerId=${this.customerId}`;
    }
    return this.http.get(`${this.serverUrl}/${routeUrl}`);
  }
}
