import { Component } from '@angular/core';
import { UserService } from 'src/app/global/service';

interface DeviceCategory {
  [categoryName: string]: string[]; // Example: "iPhone": ["iPhone 16", "iPhone 15"]
}

type BrandDevices = Record<string, DeviceCategory | string[]>; // Example: "Apple": { "iPhone": [...], "iPad": [...] }

@Component({
  selector: 'app-compatible-devices',
  templateUrl: './compatible-devices.component.html',
  styleUrls: ['./compatible-devices.component.scss']
})
export class CompatibleDevicesComponent {
  devices: BrandDevices = {};
  inProgress = false;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.inProgress = true;
    this.userService.getDevices().subscribe((data) => {
      this.devices = data;
      this.inProgress = false;
    });
  }

  isCategory(data: any): data is DeviceCategory {
    return typeof data === 'object' && !Array.isArray(data);
  }
  
}
