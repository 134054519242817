<div class="currency-card-popup">
    <div class="card-popup-body">
        <div class="card-popup-body-inner">
            <div class="top-up-container">
                <p class="top-up-text" [innerHtml]="'top_up.notification_title' | translate: {dataUsed: dataUsed}"></p>
                <button class="btn top-up-btn g-bg-primary g-color-white  cursor-pointer"
                    (click)="navigateToPlanDetails()">Top
                    Up Now</button>
            </div>
        </div>

        <button class="close-popup-div btn" (click)="closeEvent.emit()">
            <svg class="close-popup-btn cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                fill="none">
                <mask id="mask0_1_12674" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                    height="24">
                    <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1_12674)">
                    <path
                        d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                        fill="#303030" />
                </g>
            </svg>
        </button>
    </div>
</div>