import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AlertService,
  DialogComponent,
  DialogService,
  LocalStorageService,
  UserService,
} from '../../service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/features/authentication/service';
import { ConfirmDeleteAlertComponent } from '../confirm-delete-alert/confirm-delete-alert.component';
import { DeleteSuccessComponent } from 'src/app/features/account-settings/component/delete-success/delete-success.component';
import { allNavbarActions, selectUserDetails } from '../../state/user';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-delete-account-alert',
  templateUrl: './delete-account-alert.component.html',
  styleUrls: ['./delete-account-alert.component.scss'],
})
export class DeleteAccountAlertComponent implements OnInit {
  confirmEmailForm: FormGroup = new FormGroup({
    email: new FormControl({ value: '', disabled: true }),
  });
  submitted = false;
  err = false;
  errorMessage: string | null = null;
  showOTPSection = false;
  otpError = true;
  otpValue = '';
  public settings = {
    length: 6,
    numbersOnly: true,
    timer: 0,
    timerType: 1,
    btnText: 'Resend OTP',
  };
  dialogRef!: DialogComponent;
  deleteReason: string | null = null;
  userDetails$ = this.store.select(selectUserDetails);
  userDetails: any;
  isSubmitting = false;

  constructor(
    public userService: UserService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private dialogService: DialogService,
    private store: Store,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
    this.userDetails$.subscribe((details) => {
      this.userDetails = details;
      // setTimeout(() => {
      //   this.confirmEmailForm.get('email')?.setValue(this.userDetails.email);
      // });
    });

    // this.confirmEmailForm.get('email')?.valueChanges.subscribe(() => {
    //   this.showOTPSection = false;
    // });
    this.settings.btnText = this.translateService.instant('form.resend_otp');
    this.deleteReason =
      this.dialogRef.context.message || this.dialogRef.context.reason;
  }

  ngOnInit(): void {
    this.sendOtp()
  }

  get f() {
    return this.confirmEmailForm.controls;
  }

  onSubmit() {
    if (this.showOTPSection && this.otpValue.trim() != '' && !this.otpError) {
      // this.verifyOtp();
      this.confirmDeleteAccount();
    } else {
      // this.sendOtp();
    }
  }

  confirmDeleteAccount() {
    this.dialogService
      .openModal(ConfirmDeleteAlertComponent, {
        cssClass: 'modal-md',
      })
      .subscribe((result) => {
        if (result) {
          this.verifyOtp();
        }
      });
  }

  verifyOtp() {
    this.isSubmitting = true;
    const userData = {
      deleteReason: this.deleteReason,
      otp: this.otpValue,
    };
    this.userService.verifyOTP(userData).subscribe({
      next: (res: any) => {
        this.dialogRef.closeEvent.emit(true);
        this.dialogService.openModal(DeleteSuccessComponent, {
          cssClass: 'thanks-modal-contact-vw',
        });
        this.localStorageService.removeToken();
        this.store.dispatch(allNavbarActions.logoutFlowInitiated());
        this.router.navigate(['/']);
        this.isSubmitting = false;
      },
      error: (err) => {
        this.otpError = true;
        this.isSubmitting = false;
      },
    });
  }

  sendOtp() {
    this.err = false;

    const userData = {
      email: this.userDetails.email,
    };

    this.userService
      .deleteAccountOTP(userData) //api call
      .subscribe({
        next: (response: any) => {
          this.settings.timer = 120;
          this.showOTPSection = true;
          this.otpError = false;
        },
        error: (errorResponse) => {
          this.showOTPSection = false;
          this.err = true;

          if (errorResponse.error && errorResponse.error.message) {
            this.errorMessage = errorResponse.error.message;
          }
        },
      });
  }

  onOTPChange(value: any) {
    if (value.length === this.settings.length) {
      this.otpValue = value;
      this.otpError = false;
    } else if (value.length < this.settings.length) {
      this.otpValue = value;
    } else if (value === -2) {
      this.sendOtp();
    }
  }

  close(): void {
    this.dialogRef.closeEvent.emit();
  }
}
