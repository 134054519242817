import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CustomTranslateLoaderService implements TranslateLoader {
  contentHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });
  constructor(
    private httpClient: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}
  getTranslation(lang: string): Observable<any> {
    const isBrowser = isPlatformBrowser(this.platformId);
    let defaultLanguage = lang;

    if (isBrowser) {
      // Only access localStorage in the browser environment
      defaultLanguage = localStorage.getItem('language') ?? lang;
      localStorage.setItem(
        'language',
        JSON.parse(JSON.stringify(defaultLanguage))
      );
    }
    const apiAddress =
      environment.s3BucketAssets +
      `/${environment.customerId}/lang/${defaultLanguage}.json`;
    return this.httpClient
      .get(apiAddress, { headers: this.contentHeader })
      .pipe(
        catchError((_) => {
          if (isBrowser) {
            localStorage.setItem('language', JSON.parse(JSON.stringify('en')));
          }
          return this.httpClient.get(`/assets/i18n/en.json`);
        })
      );
  }

  // getTranslation(lang: string): Observable<any> {
  //   const isBrowser = isPlatformBrowser(this.platformId);
  //   let defaultLanguage = lang;

  //   if (isBrowser) {
  //     // Only access localStorage in the browser environment
  //     defaultLanguage = localStorage.getItem('language') ?? lang;
  //     localStorage.setItem('language', defaultLanguage);
  //   }

  //   // Directly return the translation from the local assets folder
  //   return this.httpClient.get(`/assets/i18n/${defaultLanguage}.json`, {
  //     headers: this.contentHeader,
  //   });
  // }
}
