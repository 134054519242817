<div class="otp-container {{setting.wrapperClass}}" id="c_{{componentKey}}" *ngIf="otpForm?.controls"
    [ngStyle]="setting.wrapperStyles" [formGroup]="otpForm">
    <div class="d-flex otp-input-group">
        <input [type]="setting.numbersOnly ? 'tel' : 'text'" appNumberOnly [disabledNumberOnly]="!setting.numbersOnly"
            [ngStyle]="setting.inputStyles" maxlength="1" class="form-control my-0 otp-input {{setting.inputClass}}"
            autocomplete="off" *ngFor="let item of otpForm?.controls | keys; let i = index"
            [formControlName]="'ctrl_' + i" placeholder="*" id="otp_{{i}}_{{componentKey}}" (keyup)="keyUp($event, i)"
            (paste)="handlePaste($event)">
    </div>
    <ng-container appCounter [counter]="setting.timer" (value)="onCounterChange($event)">
        <div class="my-md-2 mt-3 reset-pass-text">
            <label *ngIf="setting.btnLabel"> {{ setting.btnLabel }} </label>
            <a class="{{setting.btnClass}} cursor-pointer" [class.disabled]="counter !== 0" (click)="ressendOtp()">
                <!-- {{ setting.btnText}} -->
                Resend OTP
                <span *ngIf="counter && counter !== 0">
                    <ng-container *ngIf="!setting.timerType">
                        in {{ counter }} seconds.
                    </ng-container>
                    <ng-container *ngIf="setting.timerType">
                        in {{ formatSecsToMins(counter) }} minutes.
                    </ng-container>
                </span>
            </a>
        </div>
    </ng-container>
</div>