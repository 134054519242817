import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  serverUrl = environment.serverUrl;
  customerId = environment.customerId;
  isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isSignin = true;
  isForgot = false;
  isVerify = false;
  isReset = false;

  constructor(private http: HttpClient) {}

  signin(userData: any) {
    return this.http.post(`${this.serverUrl}/subscribers/signin`, userData);
  }

  signup(userData: any) {
    return this.http.post(`${this.serverUrl}/subscribers/signup`, userData);
  }

  verifyOTP(userData: any) {
    return this.http.post(`${this.serverUrl}/subscribers/verifyOTP`, userData);
  }

  forgotPassword(userData: any) {
    return this.http.post(`${this.serverUrl}/subscribers/forgot`, {
      ...userData,
      customerId: this.customerId,
    });
  }

  validateOTP(userData: any) {
    return this.http.get(
      `${this.serverUrl}/subscribers/reset/${userData.otp}/${userData.email}`
    );
  }

  resendOTP(userData: any) {
    return this.http.post(`${this.serverUrl}/subscribers/resendOTP`, {
      ...userData,
      customerId: this.customerId,
    });
  }

  resetPassword(userData: any) {
    return this.http.post(`${this.serverUrl}/subscribers/reset`, userData);
  }

  getCountries() {
    return this.http.get(
      `${this.serverUrl}/countries?customerId=${this.customerId}`
    );
  }

  socialSignIn(authenticator: string, data: any) {
    if (authenticator === 'apple') {
      // Assign default values
      const { firstName = "Apple", lastName = "User" } = data;

      // Update the object
      data = { ...data, firstName, lastName };
    }

    return this.http.post(
      `${this.serverUrl}/subscribers/signin/${authenticator}`,
      { ...data, customerId: this.customerId }
    );
  }

  getRefreshToken(userData: any) {
    return this.http.post(
      `${this.serverUrl}/subscribers/token/refreshToken`,
      userData
    );
  }

  public get isLoggedIn() {
    return this.isUserLoggedIn.asObservable();
  }

  public setIsLoggedIn(status: boolean) {
    this.isUserLoggedIn.next(status);
  }


  /**
    * Method to calculate the wait time based on the otpNextGeneration timestamp.
    *
    * This method takes an `otpNextGeneration` value (which is an epoch timestamp in UTC),
    * converts it to local time, and calculates the difference between the current time and the
    * otpNextGeneration time. The result is returned as the wait time in seconds.
    *
    * If `otpNextGeneration` is not provided or invalid, it returns a default wait time of 2 minutes (120 seconds).
    *
    * @param otpNextGeneration - The epoch timestamp when the OTP was generated.
    * @returns The calculated wait time in seconds.
    */
  calculateWaitTime(otpNextGeneration: any): number {
    if (otpNextGeneration) {
      const otpNextGenerationAt = Number(otpNextGeneration);  // Convert to number (epoch timestamp)

      // Convert OTP time (UTC) to local time (in milliseconds)
      const localOtpTime = new Date(otpNextGenerationAt).getTime();

      // Get the current time in milliseconds
      const currentTime = Date.now();

      // Calculate the wait time in seconds (difference in milliseconds divided by 1000)
      const waitTimeSeconds = Math.floor((localOtpTime - currentTime) / 1000);

      return waitTimeSeconds;  // Return the calculated wait time
    } else {
      // If otpNextGenerationAt is not present, default to waiting for 2 minutes (120 seconds)
      const defaultWaitTime = 120;
      return defaultWaitTime;  // Return the default wait time
    }
  }
}
