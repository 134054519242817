import {
  APP_ID,
  APP_INITIALIZER,
  NgModule,
  PLATFORM_ID,
  isDevMode,
} from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { Store, StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './global/state';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CountryEffects } from './global/state/country';
import { JwtInterceptor } from './global/service/interceptor/jwt.interceptor';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ReactiveFormsModule } from '@angular/forms';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfigService } from './global/service';
import { allNavbarActions, UserEffects } from './global/state/user';
import { of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { CustomTranslateLoaderService } from './global/service/custom-translate-loader.service';
import {
  SocialAuthServiceConfig,
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { selectUserDetails } from 'src/app/global/state/user';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './global/shared.module';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

declare const window: any;
export function playerFactory() {
  return player;
}

export function preloadTranslations(
  translate: TranslateService,
  platformId: Object
) {
  return () => {
    let lang = 'en'; // Default language
    if (isPlatformBrowser(platformId)) {
      lang = localStorage.getItem('language') || 'en';
    }
    return translate.use(lang).toPromise();
  };
}

// Create a loader for translations
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const appInitializerFn = (
  configService: ConfigService,
  store: Store,
  platformId: any
) => {
  'use strict';
  return () => {
    const userDetails$ = store.select(selectUserDetails);
    let userDetails;
    userDetails$.subscribe((details) => (userDetails = details));
    if (isPlatformBrowser(platformId)) {
      const token = localStorage.getItem('authToken');
      if (token && !userDetails) {
        return of(
          configService.getConfigFromServer(),
          store.dispatch(allNavbarActions.loginFlowInitiated())
        );
      } else {
        return configService.getConfigFromServer();
      }
    }
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    TransferHttpCacheModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([CountryEffects, UserEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    LottieModule.forRoot({ player: playerFactory }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoaderService,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
  ],
  providers: [
    { provide: APP_ID, useValue: 'GlowingBudApp' },
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
    GoogleTagManagerService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigService, Store, PLATFORM_ID],
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleSocialAuth, {
              scopes: 'openid profile email',
              oneTapEnabled: false,
            }),
          },
        ],
        onError: (err: any) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: preloadTranslations,
      deps: [TranslateService, PLATFORM_ID],
      multi: true,
    },

    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
