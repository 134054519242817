import {
  Component,
  OnInit,
  SecurityContext,
  ViewContainerRef,
} from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertService } from '../../service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-installation-guide',
  templateUrl: './installation-guide.component.html',
  styleUrls: ['./installation-guide.component.scss'],
})
export class InstallationGuideComponent implements OnInit {
  dialogRef: any;
  currentStep = 0;
  selectedDevice!: string;
  selectedInstructionType!: string;
  totalButtons!: any;
  isDownload = false;

  guideArray: Array<any> = [];

  iosGuideObj: any = {
    automatic: [
      {
        heading: 'installation.guide.ios.automatic.heading.0',
        subHeading: 'installation.guide.ios.automatic.subHeading.0',
        description: 'installation.guide.ios.automatic.description.0',
        img: 'installation.guide.ios.automatic.img.0',
      },
      {
        heading: 'installation.guide.ios.automatic.heading.1',
        subHeading: 'installation.guide.ios.automatic.subHeading.1',
        description: 'installation.guide.ios.automatic.description.1',
        img: 'installation.guide.ios.automatic.img.1',
      },
      {
        heading: 'installation.guide.ios.automatic.heading.2',
        subHeading: 'installation.guide.ios.automatic.subHeading.2',
        description: 'installation.guide.ios.automatic.description.2',
        img: 'installation.guide.ios.automatic.img.2',
      },
      {
        heading: 'installation.guide.ios.automatic.heading.3',
        subHeading: 'installation.guide.ios.automatic.subHeading.3',
        description: 'installation.guide.ios.automatic.description.3',
        img: 'installation.guide.ios.automatic.img.3',
      },
    ],
    qr: [
      {
        heading: 'installation.guide.ios.qr.heading.0',
        subHeading: 'installation.guide.ios.qr.subHeading.0',
        description: 'installation.guide.ios.qr.description.0',
        img: 'installation.guide.ios.qr.img.0',
      },
      {
        heading: 'installation.guide.ios.qr.heading.1',
        subHeading: 'installation.guide.ios.qr.subHeading.1',
        description: 'installation.guide.ios.qr.description.1',
        img: 'installation.guide.ios.qr.img.1',
      },
      {
        heading: 'installation.guide.ios.qr.heading.2',
        subHeading: 'installation.guide.ios.qr.subHeading.2',
        description: 'installation.guide.ios.qr.description.2',
        img: 'installation.guide.ios.qr.img.2',
      },
      {
        heading: 'installation.guide.ios.qr.heading.3',
        subHeading: 'installation.guide.ios.qr.subHeading.3',
        description: 'installation.guide.ios.qr.description.3',
        img: 'installation.guide.ios.qr.img.3',
      },
      {
        heading: 'installation.guide.ios.qr.heading.4',
        subHeading: 'installation.guide.ios.qr.subHeading.4',
        description: 'installation.guide.ios.qr.description.4',
        img: 'installation.guide.ios.qr.img.4',
      },
      {
        heading: 'installation.guide.ios.qr.heading.5',
        subHeading: 'installation.guide.ios.qr.subHeading.5',
        description: 'installation.guide.ios.qr.description.5',
        img: 'installation.guide.ios.qr.img.5',
      },
    ],
    manual: [
      {
        heading: 'installation.guide.ios.manual.heading.0',
        subHeading: 'installation.guide.ios.manual.subHeading.0',
        description: 'installation.guide.ios.manual.description.0',
        img: 'installation.guide.ios.manual.img.0',
      },
      {
        heading: 'installation.guide.ios.manual.heading.1',
        subHeading: 'installation.guide.ios.manual.subHeading.1',
        description: 'installation.guide.ios.manual.description.1',
        img: 'installation.guide.ios.manual.img.1',
      },
      {
        heading: 'installation.guide.ios.manual.heading.2',
        subHeading: 'installation.guide.ios.manual.subHeading.2',
        description: 'installation.guide.ios.manual.description.2',
        img: 'installation.guide.ios.manual.img.2',
      },
      {
        heading: 'installation.guide.ios.manual.heading.3',
        subHeading: 'installation.guide.ios.manual.subHeading.3',
        description: 'installation.guide.ios.manual.description.3',
        img: 'installation.guide.ios.manual.img.3',
      },
      {
        heading: 'installation.guide.ios.manual.heading.4',
        subHeading: 'installation.guide.ios.manual.subHeading.4',
        description: 'installation.guide.ios.manual.description.4',
        img: 'installation.guide.ios.manual.img.4',
      },
      {
        heading: 'installation.guide.ios.manual.heading.5',
        subHeading: 'installation.guide.ios.manual.subHeading.5',
        description: 'installation.guide.ios.manual.description.5',
        img: 'installation.guide.ios.manual.img.5',
      },
      {
        heading: 'installation.guide.ios.manual.heading.6',
        subHeading: 'installation.guide.ios.manual.subHeading.6',
        description: 'installation.guide.ios.manual.description.6',
        img: 'installation.guide.ios.manual.img.6',
      },
    ],
  };

  androidGuideObj: any = {
    automatic: [
      {
        heading: 'installation.guide.android.automatic.heading.0',
        subHeading: 'installation.guide.android.automatic.subHeading.0',
        description: 'installation.guide.android.automatic.description.0',
        img: 'installation.guide.android.automatic.img.0',
      },
      {
        heading: 'installation.guide.android.automatic.heading.1',
        subHeading: 'installation.guide.android.automatic.subHeading.1',
        description: 'installation.guide.android.automatic.description.1',
        img: 'installation.guide.android.automatic.img.1',
      },
    ],
    qr: [
      {
        heading: 'installation.guide.android.qr.heading.0',
        subHeading: 'installation.guide.android.qr.subHeading.0',
        description: 'installation.guide.android.qr.description.0',
        img: 'installation.guide.android.qr.img.0',
      },
      {
        heading: 'installation.guide.android.qr.heading.1',
        subHeading: 'installation.guide.android.qr.subHeading.1',
        description: 'installation.guide.android.qr.description.1',
        img: 'installation.guide.android.qr.img.1',
      },
      {
        heading: 'installation.guide.android.qr.heading.2',
        subHeading: 'installation.guide.android.qr.subHeading.2',
        description: 'installation.guide.android.qr.description.2',
        img: 'installation.guide.android.qr.img.2',
      },
      {
        heading: 'installation.guide.android.qr.heading.3',
        subHeading: 'installation.guide.android.qr.subHeading.3',
        description: 'installation.guide.android.qr.description.3',
        img: 'installation.guide.android.qr.img.3',
      },
    ],
    manual: [
      {
        heading: 'installation.guide.android.manual.heading.0',
        subHeading: 'installation.guide.android.manual.subHeading.0',
        description: 'installation.guide.android.manual.description.0',
        img: 'installation.guide.android.manual.img.0',
      },
      {
        heading: 'installation.guide.android.manual.heading.1',
        subHeading: 'installation.guide.android.manual.subHeading.1',
        description: 'installation.guide.android.manual.description.1',
        img: 'installation.guide.android.manual.img.1',
      },
      {
        heading: 'installation.guide.android.manual.heading.2',
        subHeading: 'installation.guide.android.manual.subHeading.2',
        description: 'installation.guide.android.manual.description.2',
        img: 'installation.guide.android.manual.img.2',
      },
      {
        heading: 'installation.guide.android.manual.heading.3',
        subHeading: 'installation.guide.android.manual.subHeading.3',
        description: 'installation.guide.android.manual.description.3',
        img: 'installation.guide.android.manual.img.3',
      },
      {
        heading: 'installation.guide.android.manual.heading.4',
        subHeading: 'installation.guide.android.manual.subHeading.4',
        description: 'installation.guide.android.manual.description.4',
        img: 'installation.guide.android.manual.img.4',
      },
      // {
      //   heading: 'installation.guide.android.manual.heading.5',
      //   subHeading: 'installation.guide.android.manual.subHeading.5',
      //   description: 'installation.guide.android.manual.description.5',
      //   img: 'installation.guide.android.manual.img.5',
      // },
    ],
  };
  inProgress = false;

  customValueForButton!: string;
  customButtonText!: string;
  activationCodeForQR!: string;
  qrCodeDownloadLink!: SafeUrl;

  constructor(
    private viewContainer: ViewContainerRef,
    private sanitize: DomSanitizer,
    private router: Router,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  ngOnInit() {
    this.inProgress = true;

    this.selectedDevice = this.dialogRef.context.deviceType;
    this.selectedInstructionType = this.dialogRef.context.instructionType;
    this.customValueForButton = this.dialogRef.context.customValueForButton;
    this.customButtonText = this.dialogRef.context.customButtonText;
    this.activationCodeForQR = this.dialogRef.context.activationCodeForQR;
    this.isDownload = this.customButtonText.includes('Download') ? true : false;

    setTimeout(() => {
      if (this.selectedDevice === 'ios') {
        this.guideArray = this.iosGuideObj[this.selectedInstructionType];
      } else if (this.selectedDevice === 'android') {
        this.guideArray = this.androidGuideObj[this.selectedInstructionType];
      }

      this.totalButtons = Array.from(
        { length: this.guideArray.length + 1 },
        (_, x) => x
      );
      this.inProgress = false;
    }, 10);
  }

  copyToClipboard(event: any) {
    event.preventDefault();

    if (!this.customValueForButton) {
      return;
    }

    navigator.clipboard.writeText(this.customValueForButton);
    this.dialogRef.closeEvent.emit();
    this.currentStep = 0;

    if (
      !(
        this.selectedDevice === 'ios' &&
        this.selectedInstructionType === 'manual'
      )
    ) {
      this.router.navigate(['/my-plans']);
    }
  }

  next() {
    this.currentStep++;
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  downloadQRCode() {
    // this.alertService.success(
    //   this.translateService.instant('alert.qr_to_device')
    // );
    this.dialogRef.closeEvent.emit();
    this.currentStep = 0;
    this.router.navigate(['/my-plans']);
  }

  close() {
    this.dialogRef.closeEvent.emit();
    this.currentStep = 0;
  }
}
