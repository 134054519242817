<!-- <div class="d-flex justify-content-center align-items-center vh-100" *ngIf="languageLoading">
  <app-loader></app-loader>
</div>

<div [class.d-none]="languageLoading">
  <div *ngIf="isBrowser else serverTemp">
    <router-outlet></router-outlet>
 </div>
 <ng-template #serverTemp>
    <div style="display: none">
        <router-outlet></router-outlet>
    </div>
 </ng-template>
</div> -->


<ng-container *ngIf="isBrowser; else serverTemp">
  <router-outlet></router-outlet>
</ng-container>
<ng-template #serverTemp>
  <!-- This will render only during SSR and won't cause hydration mismatch -->
  <router-outlet></router-outlet>
</ng-template>