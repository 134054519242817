<div class="bg-white install-guide-modal-container">
  <button class="btn install-guide-cross-div" (click)="close()">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5202 2.72921C17.6702 2.584 17.7899 2.41027 17.8723 2.21817C17.9546 2.02606 17.998 1.81943 17.9999 1.61032C18.0018 1.4012 17.9622 1.1938 17.8834 1.00022C17.8045 0.806634 17.688 0.63074 17.5407 0.482799C17.3934 0.334859 17.2181 0.217836 17.0252 0.138557C16.8323 0.0592791 16.6256 0.0193341 16.4171 0.0210519C16.2087 0.0227698 16.0027 0.0661165 15.8111 0.148563C15.6195 0.23101 15.4462 0.350906 15.3013 0.501254L9.00889 6.81133L2.71857 0.501254C2.57484 0.346522 2.40151 0.222416 2.20893 0.136339C2.01635 0.0502612 1.80846 0.00397609 1.59766 0.000245095C1.38686 -0.0034859 1.17747 0.0354137 0.981983 0.114623C0.786495 0.193831 0.608913 0.311727 0.459832 0.461277C0.310751 0.610826 0.193225 0.788964 0.114264 0.985066C0.0353029 1.18117 -0.00347499 1.39122 0.000244328 1.60268C0.00396364 1.81414 0.0501039 2.02268 0.135912 2.21587C0.22172 2.40905 0.345437 2.58293 0.499685 2.72711L6.78582 9.03928L0.495498 15.3494C0.21818 15.6479 0.0672051 16.0428 0.0743814 16.4508C0.0815576 16.8588 0.246325 17.2481 0.533969 17.5366C0.821614 17.8252 1.20968 17.9905 1.6164 17.9977C2.02313 18.0049 2.41677 17.8534 2.71438 17.5752L9.00889 11.2651L15.2992 17.5773C15.5968 17.8555 15.9905 18.007 16.3972 17.9998C16.8039 17.9926 17.192 17.8273 17.4796 17.5387C17.7673 17.2502 17.932 16.8609 17.9392 16.4529C17.9464 16.0449 17.7954 15.65 17.5181 15.3515L11.232 9.03928L17.5202 2.72921Z"
        fill="black" />
    </svg>
  </button>
  <div class="install-guide-modal-flex-container">
    <!-- <ng-container *ngFor="let step of guideArray; let i = index">
      <div class="install-guide-modal-flex" *ngIf="currentStep === i">
        <div class="install-modal-container">
          <div class="install-modal-image-container simmer">
            <img class="img-fluid" [src]="step.img" alt="" />
          </div>
          <div class="install-modal-description-container">
            <h4 class="install-modal-description-head ">
              {{ step.heading }}
            </h4>
            <div class="install-modal-text-description">
              <h4 class="install-modal-text-description-head ">
                {{ step.subHeading }}
              </h4>
              <p class="install-modal-text-description-subhead ">
                {{ step.description }}
              </p>
            </div>
            <div class="btn-guide-steps-container">
              <ng-container *ngFor="let btn of totalButtons; let i = index">
                <span
                  class="btn-guide-steps"
                  [ngClass]="{ 'btn-primary_class': currentStep === btn }"
                ></span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container> -->
    <div class="install-guide-modal-flex"
      [ngClass]="{'install-guide-modal-flex-mob': !(currentStep === guideArray.length)}">
      <div class="install-guide-modal-cluch"></div>
      <ng-container *ngFor="let step of guideArray; let i = index">
        <div class="install-modal-container" *ngIf="currentStep === i">
          <h4 class="install-modal-description-head lato-semibold d-md-none">
            {{ step.heading | translate }}
          </h4>
          <div class="install-modal-image-container simmer">
            <img class="img-fluid" [src]='step.img | translate' alt="" />
          </div>
          <div class="install-modal-description-container">
            <h4 class="install-modal-description-head lato-semibold d-none d-md-block">
              {{ step.heading | translate }}
            </h4>
            <div class="install-modal-text-description">
              <h4 class="install-modal-text-description-head lato-semibold">
                {{ step.subHeading | translate }}
              </h4>
              <p class="install-modal-text-description-subhead league-spartan-regular">
                {{ step.description | translate }}
              </p>
            </div>
            <div class="btn-guide-steps-container step-web">
              <ng-container *ngFor="let btn of totalButtons; let i = index">
                <span class="btn-guide-steps" [ngClass]="{ 'btn-primary_class': currentStep === btn }"></span>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #nextBtn>
        <div class="install-guide-modal-btn-container">
          <button type="button" class="btn install-guide-modal-btn" (click)="next()">
            {{ "installation.next" | translate }}
          </button>
        </div>

        <div class="install-guide-modal-btn-container-mob">
          <div class="btn-guide-steps-container step-mob">
            <ng-container *ngFor="let btn of totalButtons; let i = index">
              <span class="btn-guide-steps" [ngClass]="{ 'btn-primary_class': currentStep === btn }"></span>
            </ng-container>
          </div>
          <button type="button" class="btn install-guide-modal-btn" (click)="next()">
            {{ "installation.next" | translate }}
          </button>
        </div>
      </ng-template>
    </div>
    <ng-container *ngIf="currentStep === guideArray.length; else nextBtn">
      <div class="install-guide-modal-flex install-guide-modal-flex-thankyou">
        <div class="install-modal-container install-modal-thank-you-container">
          <!-- <p class=" final-content">
            {{ "installation.guide.final_step" | translate }}
          </p> -->
          <img class="install-modal-thank-you-img"
            src="https://assets.glowingbud.com/GB-Web-App/Holiday/common/install-thanks.png" alt="" />
          <div>
            <h3 class="install-modal-thank-you-head">{{ "installation.guide.final_step_title" | translate }}</h3>
            <p class="install-modal-thank-you-subhead">{{ 'installation.guide.final_step_sub_text' | translate }}</p>
          </div>
          <div class="install-modal-thank-you-btn-container">
            <button type="button" class="btn btn-do-it-letter" (click)="close()">
              {{ "installation.do_later" | translate }}
            </button>

            <ng-container>
              <button type="button" class="btn btn-do-lpa-stringr" (click)="copyToClipboard($event)"
                *ngIf="!isDownload && !(selectedDevice === 'android' && selectedInstructionType === 'automatic')">
                {{ customButtonText }}
              </button>
              <a class="btn btn-do-lpa-stringr" [href]="qrCodeDownloadLink" download="qrcode" *ngIf="isDownload"
                (click)="downloadQRCode()">{{ customButtonText }}</a>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>



    <qrcode #parent [qrdata]="activationCodeForQR" [allowEmptyString]="true" cssClass="center qr-canvas d-none"
      elementType="canvas" errorCorrectionLevel="H" [imageSrc]="'assets/images/auth/gb-small-new.jpg'"
      [imageHeight]="100" [imageWidth]="100" [scale]="1" [title]="'installation.scan_qr' | translate" [width]="300"
      [margin]="2" (qrCodeURL)="onChangeURL($event)">
    </qrcode>
  </div>
</div>