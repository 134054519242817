import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { BrowserService } from './global/service';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, ConfigService } from 'src/app/global/service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'esim-management-portal-application-web-app';
  isBrowser = false;
  private readonly FIRST_VISIT_KEY = 'firstVisitDone';

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private browserService: BrowserService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private configService: ConfigService,
    private gtmService: GoogleTagManagerService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    browserService.setIsBrowser(this.isBrowser);
    configService.getLocalConfig().subscribe((config: any) => {
      const configDataLocal = JSON.parse(
        this.localStorageService.getItem('config')!
      );
      let configData;
      if (!configDataLocal) {
        configData = config;
      } else {
        configData = JSON.parse(this.localStorageService.getItem('config')!);
      }
      const defaultLang =
        this.localStorageService.getItem('language') ??
        configData?.languages?.default;
      const supportedLanguagesList = configData?.languages?.supported?.map(
        (country: any) => country.code
      );
      if (supportedLanguagesList)
        this.translateService.addLangs(supportedLanguagesList);
      this.translateService.setDefaultLang(defaultLang);
      this.translateService.use(defaultLang);
    });
  }

  ngOnInit() {
    this.trackFirstVisit();
  }

  private trackFirstVisit() {
    // Check if the platform is the browser
    if (this.isBrowser) {
      const hasVisited = localStorage.getItem(this.FIRST_VISIT_KEY);

      if (!hasVisited) {
        this.sendGTMEvent();
        localStorage.setItem(this.FIRST_VISIT_KEY, 'true');
      }
    }
  }

  private sendGTMEvent() {
    this.gtmService.pushTag({
      event: 'first_visit',
      pagePath: window.location.pathname,
    });
  }
}
