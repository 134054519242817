<section class="compatible-device-section">
  <div class="container">
    <app-page-title [title]="'eSIM-compatible devices'" [subDetails]="'Last Updated: November 2024'"
      backToRoute="/" ></app-page-title>
    <ng-container *ngIf="!inProgress">
      <div class="page-explore-card-container">
        <div class="page-explore-card-shadow"></div>
        <div class="page-explore-card">
          <div class="compatible-device-inner">
            <p class="note-text">Please note your phone must be carrier unlocked to use an eSIM.</p>

            <!-- Iterate over brands -->
            <div *ngFor="let brand of devices | preserveOrder">
              <p class="brand-name">{{ brand.key }}</p>

              <!-- If the value is a nested category -->
              <div *ngIf="isCategory(brand.value)">
                <div *ngFor="let category of brand.value | preserveOrder">
                  <p class="compatible-devices-text">{{ category.key }}</p>
                  <ul class="compatible-device-list">
                    <li *ngFor="let device of category.value">{{ device }}</li>
                  </ul>
                </div>
              </div>

              <!-- If the value is a flat list -->
              <div *ngIf="!isCategory(brand.value)">
                <!-- <p class="compatible-devices-text">Compatible devices</p> -->
                <ul class="compatible-device-list">
                  <li *ngFor="let device of brand.value">{{ device }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-note-container">
        <p class="bottom-note-text">
          *Some versions of this device may be incompatible with eSIMS—check the device’s manual or contact the device
          manufacturer.
        </p>
      </div>
    </ng-container>

    <div class="w-auto m-auto d-flex justify-content-center" *ngIf="inProgress">
      <app-loader></app-loader>
    </div>
  </div>
</section>