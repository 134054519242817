<div class="currency-card-popup" *ngIf="defaultCurrency">
    <div class="card-popup-body">
        <div class="card-popup-body-inner">
            <svg class="icon-currency" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none">
                <g clip-path="url(#clip0_5675_87658)">
                    <path
                        d="M10.366 8.5322H8.45441C7.99441 8.5322 7.62012 8.15749 7.62012 7.69696C7.62012 7.23484 7.99382 6.86172 8.45429 6.86172C8.45859 6.86172 8.46282 6.86178 8.46712 6.86178H10.2976C10.6264 6.86508 10.9514 7.01272 11.1898 7.26731C11.523 7.62296 12.0813 7.64108 12.437 7.30802C12.7926 6.97484 12.8109 6.41649 12.4777 6.06084C11.9096 5.45437 11.1188 5.10314 10.3079 5.09708C10.3057 5.09708 10.3035 5.09708 10.3014 5.09708H10.2926V4.31302C10.2926 3.82572 9.89753 3.43066 9.41023 3.43066C8.92294 3.43066 8.52788 3.82572 8.52788 4.31302V5.09702C6.92641 5.09702 5.85547 6.32567 5.85547 7.6969C5.85547 9.13049 7.02135 10.2968 8.45447 10.2968H10.3661C10.8261 10.2968 11.2004 10.6715 11.2004 11.132C11.2004 11.5942 10.8266 11.9673 10.3662 11.9673C10.3619 11.9673 10.3576 11.9672 10.3534 11.9672H8.35617C8.124 11.9648 7.87747 11.8811 7.66164 11.7313C7.26129 11.4532 6.71153 11.5525 6.43365 11.9529C6.15576 12.3532 6.25494 12.903 6.65529 13.1808C7.29082 13.6221 7.86323 13.7319 8.52794 13.7319V14.5159C8.52794 15.0032 8.923 15.3983 9.41029 15.3983C9.89759 15.3983 10.2926 15.0032 10.2926 14.5159V13.7319C11.9056 13.7319 12.9651 12.4926 12.9651 11.132C12.965 9.69849 11.7991 8.5322 10.366 8.5322Z"
                        fill="black" />
                    <path
                        d="M18.6148 11.38C19.8644 5.49535 15.3565 0 9.41012 0C4.22135 0 0 4.22347 0 9.41476C0 15.3644 5.49718 19.8818 11.3865 18.6212C10.1376 24.485 14.6263 30 20.5899 30C25.7786 30 30 25.7766 30 20.5853C30 14.6374 24.5034 10.1165 18.6148 11.38ZM1.76471 9.41476C1.76471 5.19653 5.19441 1.76471 9.41012 1.76471C14.7176 1.76471 18.4291 7.08182 16.5831 12.0681C14.6052 13.0032 13.0034 14.608 12.0714 16.5884C7.08965 18.4427 1.76471 14.7336 1.76471 9.41476ZM20.5899 28.2353C16.3743 28.2353 12.9446 24.8035 12.9446 20.5853C12.9446 16.3671 16.3743 12.9354 20.5899 12.9354C24.8056 12.9354 28.2353 16.3671 28.2353 20.5853C28.2353 24.8035 24.8056 28.2353 20.5899 28.2353Z"
                        fill="black" />
                    <path
                        d="M23.602 23.6794C22.3703 23.2884 21.083 23.1641 19.8143 23.3081C19.9931 22.7596 20.1191 22.1967 20.1916 21.6252H21.249C21.7363 21.6252 22.1313 21.2302 22.1313 20.7429C22.1313 20.2556 21.7363 19.8605 21.249 19.8605H20.2444C20.2262 19.5815 20.215 19.502 20.1403 18.8204C20.0165 17.6908 21.4186 17.0867 22.1609 17.9238C22.4841 18.2884 23.0418 18.3221 23.4065 17.9987C23.7711 17.6754 23.8047 17.1177 23.4813 16.7531C21.6087 14.6408 18.0744 16.1723 18.3861 19.0127C18.4657 19.7389 18.4598 19.6738 18.4748 19.8605H17.8472C17.3599 19.8605 16.9648 20.2555 16.9648 20.7428C16.9648 21.2301 17.3599 21.6252 17.8472 21.6252H18.4087C18.273 22.484 17.9867 23.3161 17.555 24.0908C17.1738 24.7748 17.8109 25.5822 18.5649 25.3696C20.059 24.949 21.4523 24.8482 23.0681 25.3612C23.5318 25.5085 24.0285 25.2524 24.1761 24.7872C24.3234 24.3228 24.0664 23.8268 23.602 23.6794Z"
                        fill="black" />
                    <path
                        d="M21.9098 2.77496C23.6388 2.77496 25.4764 4.08055 25.4764 6.50014V7.33096L24.6818 6.5359C24.3373 6.1912 23.7786 6.1912 23.4339 6.53555C23.0893 6.88002 23.0891 7.43873 23.4336 7.78337L25.7346 10.0857C26.0759 10.4275 26.6363 10.4325 26.9828 10.0857L29.2839 7.78337C29.6283 7.43873 29.6282 6.88008 29.2835 6.53555C28.9389 6.19102 28.3802 6.1912 28.0357 6.5359L27.241 7.33102V6.50014C27.241 3.29008 24.8023 1.01025 21.9097 1.01025C21.4224 1.01025 21.0273 1.40531 21.0273 1.89261C21.0273 2.3799 21.4225 2.77496 21.9098 2.77496Z"
                        fill="black" />
                    <path
                        d="M8.08947 27.2251C6.36047 27.2251 4.52288 25.9195 4.52288 23.4999V22.669L5.31759 23.4642C5.662 23.8087 6.22059 23.8091 6.56541 23.4645C6.91012 23.12 6.91024 22.5613 6.56577 22.2167L4.26465 19.9143C3.9233 19.5722 3.36177 19.5683 3.01653 19.9143L0.715296 22.2167C0.370825 22.5614 0.370943 23.12 0.715649 23.4646C1.06024 23.809 1.61894 23.8089 1.96347 23.4642L2.75818 22.6691V23.4999C2.75818 26.71 5.19683 28.9898 8.08947 28.9898C8.57677 28.9898 8.97183 28.5947 8.97183 28.1075C8.97183 27.6202 8.57677 27.2251 8.08947 27.2251Z"
                        fill="black" />
                </g>
                <defs>
                    <clipPath id="clip0_5675_87658">
                        <rect width="30" height="30" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <div class="d-inline">
                <p class="currency-text  g-color-dark"
                    [innerHtml]=" 'settings.currency_info.current_currency_title' | translate: { currencyName: defaultCurrency.currency_name, currencySymbol: (defaultCurrency.currency || '') | currencySymbol } ">
                </p>
                <a class="change-currency-text  cursor-pointer" routerLink="/settings/currency">{{
                    'settings.currency_info.change_currency' | translate }}</a>
            </div>
        </div>

        <div class="close-popup-div">
            <svg class="close-popup-btn cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                fill="none" (click)="closeEvent.emit()">
                <mask id="mask0_1_12674" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                    height="24">
                    <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1_12674)">
                    <path
                        d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                        fill="#303030" />
                </g>
            </svg>
        </div>
    </div>
</div>