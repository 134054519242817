import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'preserveOrder',
})
export class PreserveOrderPipe implements PipeTransform {
  transform(value: Record<string, any>): { key: string; value: any }[] {
    if (!value || typeof value !== 'object') {
      return [];
    }
    // Convert object into array of key-value pairs
    return Object.keys(value).map((key) => ({
      key,
      value: value[key],
    }));
  }
}
