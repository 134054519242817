import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/global/service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private orderSubject: BehaviorSubject<any>;
  public order$: Observable<any>;
  // Create a BehaviorSubject to store the array of dismissed plan IDs.
  private dismissedPlansSubject = new BehaviorSubject<string[]>([]);

  // Expose an Observable to subscribe to.
  dismissedPlans$ = this.dismissedPlansSubject.asObservable();


  constructor(
    private localStorageService: LocalStorageService,
  ) {
    const initialOrder = this.loadOrderFromLocalStorage(); // Load the order from localStorage
    this.orderSubject = new BehaviorSubject<any>(initialOrder);
    this.order$ = this.orderSubject.asObservable();
  }

  // Load order from localStorage or return default values if not available
  private loadOrderFromLocalStorage(): any {
    const storedOrder = this.localStorageService.getItem('currentOrder');
    return storedOrder ? JSON.parse(storedOrder) : this.getDefaultOrder();
  }

  // Save the current order to localStorage
  private saveOrderToLocalStorage(order: any): void {
    this.localStorageService.setItem('currentOrder', JSON.stringify(order));
  }

  // Get the default order (used for initializing and resetting the order)
  private getDefaultOrder(): any {
    return {
      destination: {},
      selectedPlan: {},
      orderSummary: {},
    };
  }

  /**
   * Set the destination for the order and update localStorage.
   * @param destinationData - Partial destination data to set.
   */
  setDestination(destinationData: any): void {
    const currentOrder = this.orderSubject.getValue();
    const updatedOrder = {
      ...currentOrder,
      destination: destinationData ? { ...destinationData } : {}
    };
    this.orderSubject.next(updatedOrder);
    this.saveOrderToLocalStorage(updatedOrder);
  }

  /**
   * Set a common plan for all travelers and update localStorage.
   * @param selectedPlan - Common plan to set.
   */
  setCommonPlan(selectedPlan: any): void {
    const currentOrder = this.orderSubject.getValue();
    const updatedOrder = { ...currentOrder, selectedPlan };
    this.orderSubject.next(updatedOrder);
    this.saveOrderToLocalStorage(updatedOrder);
  }

  /**
   * Set the current order and update localStorage.
   * @param order - Order data to set.
   */
  setCurrentOrder(order: any): void {
    this.orderSubject.next(order);
    this.saveOrderToLocalStorage(order);
  }

  /**
   * Get the current order as an observable.
   * @returns Observable of the current order.
   */
  getCurrentOrder(): Observable<any> {
    return this.order$;
  }

  /**
   * Get the current order details directly without subscribing.
   * @returns The latest value of the order.
   */
  getCurrentOrderValue(): any {
    return this.orderSubject.getValue();
  }

  /**
   * Remove the selected destination and update localStorage.
   */
  unsetDestination(): void {
    const currentOrder = this.orderSubject.getValue();
    const updatedOrder = { ...currentOrder, destination: {} };
    this.orderSubject.next(updatedOrder);
    this.saveOrderToLocalStorage(updatedOrder);
  }

  /**
   * Remove the selected common plan and update localStorage.
   */
  unsetCommonPlan(): void {
    const currentOrder = this.orderSubject.getValue();
    const updatedOrder = { ...currentOrder, selectedPlan: {} };
    this.orderSubject.next(updatedOrder);
    this.saveOrderToLocalStorage(updatedOrder);
  }

  /**
   * Reset the order to default values and update localStorage.
   */
  resetOrder(): void {
    const resetOrder = this.getDefaultOrder();
    this.orderSubject.next(resetOrder);
    this.localStorageService.removeItem('currentOrder');
    this.localStorageService.removeItem('stripeKeys');
    // this.saveOrderToLocalStorage(resetOrder);
  }

  /**
   * Set the order summary for the current order and update localStorage.
   * @param orderSummary - Order summary data to set.
   */
  setOrderSummary(orderSummary: any): void {
    const currentOrder = this.orderSubject.getValue();
    const updatedOrder = {
      ...currentOrder,
      orderSummary: { ...orderSummary }
    };
    this.orderSubject.next(updatedOrder);
    this.saveOrderToLocalStorage(updatedOrder);
  }

  /**
   * Unset the order summary by resetting it to default values and updating localStorage.
   */
  unsetOrderSummary(): void {
    const currentOrder = this.orderSubject.getValue();
    const updatedOrder = {
      ...currentOrder,
      orderSummary: {}
    };
    this.orderSubject.next(updatedOrder);
    this.saveOrderToLocalStorage(updatedOrder);
  }

  // Add a plan ID to the dismissed list
  dismissPlan(planId: string) {
    const dismissedPlans = this.dismissedPlansSubject.value;
    if (!dismissedPlans.includes(planId)) {
      dismissedPlans.push(planId);
      this.dismissedPlansSubject.next(dismissedPlans); // Emit the updated list
    }
  }

  // Check if the plan has been dismissed
  isPlanDismissed(planId: string): boolean {
    const dismissedPlans = this.dismissedPlansSubject.value;
    return this.dismissedPlansSubject.value.includes(planId);
  }

}
