import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'internetUsage',
})
export class InternetUsagePipe implements PipeTransform {
  // transform(bytes: number, threshold: number = 1024 * 1024): string {
  //   if (bytes > threshold) {
  //     return `${(bytes / (1024 * 1024)).toFixed(0)} MB`;
  //   } else {
  //     return `${(bytes / 1024).toFixed(0)} GB`;
  //   }
  // }

  transform(used: number, totalUsage: number): string {
    let displayText = '';

    if (used >= 1024) {
      const totalData = totalUsage >= 1024 ? totalUsage / 1024 : totalUsage;
      const usedData = used / 1024;
      displayText = `${usedData.toFixed(2)} GB / ${totalData.toFixed(2)}`;
    } else {
      const totalData = totalUsage >= 1024 ? totalUsage / 1024 : totalUsage;
      displayText = `${used} MB / ${totalData}`;
    }

    return `${displayText} ${totalUsage >= 1024 ? 'GB' : 'MB'}`;
  }
}

@Pipe({
  name: 'dataInMBGB',
})
export class DataInMbGbPipe implements PipeTransform {
  transform(data: number): string {
    let displayText = '';

    if (data < 1) {
      const finalData = data * 1024;
      displayText = `${finalData} MB`;
    } else {
      displayText = `${data} GB`;
    }

    return displayText;
  }
}

@Pipe({
  name: 'dataInGB',
})
export class DataInGbPipe implements PipeTransform {
  transform(used: number, total: number): string {
    // Convert both values to GB
    const usedInGb = used / 1024;
    const totalInGb = total / 1024;
    // Format the total data to show decimals only when it's not an integer
    const totalFormatted =
      totalInGb % 1 === 0 ? Math.floor(totalInGb) : totalInGb.toFixed(1);
    // Format used data: if `used` is 0, display without decimals
    const usedFormatted = usedInGb === 0 ? '0' : usedInGb.toFixed(2);
    return `${usedFormatted} GB / ${totalFormatted} GB`;
  }
}
