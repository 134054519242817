import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'remainingCharacters',
})
export class RemainingCharactersPipe implements PipeTransform {
  transform(maxLength: number, currentMessage: string): number {
    if (!currentMessage) {
      currentMessage = '';
    }
    return currentMessage.length;
  }
}
