import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-up',
  templateUrl: './top-up.component.html',
  styleUrls: ['./top-up.component.scss'],
})
export class TopUpComponent implements OnInit {
  @Input() activePlan!: any;
  @Input() dataUsed!: any;
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,

  ) { }

  ngOnInit() {
  }

  navigateToPlanDetails(): void {
    const planDetails = this.activePlan;
    this.router.navigate([
      '/plans',
      planDetails.countryName,
      planDetails._id,
      planDetails.productId,
      planDetails.imsi,
      planDetails.isCountry,
    ]);
  }
}
