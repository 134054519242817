<div class="holiday-contact-header d-none">
    <div class="container">
        <div class="holiday-contact-header-flex">
            <span class="contact-icon">
                <svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none">
                    <path
                        d="M19.0508 4.91006C18.134 3.98399 17.042 3.24973 15.8384 2.75011C14.6349 2.25049 13.3439 1.99552 12.0408 2.00006C6.58078 2.00006 2.13078 6.45006 2.13078 11.9101C2.13078 13.6601 2.59078 15.3601 3.45078 16.8601L2.05078 22.0001L7.30078 20.6201C8.75078 21.4101 10.3808 21.8301 12.0408 21.8301C17.5008 21.8301 21.9508 17.3801 21.9508 11.9201C21.9508 9.27006 20.9208 6.78006 19.0508 4.91006ZM12.0408 20.1501C10.5608 20.1501 9.11078 19.7501 7.84078 19.0001L7.54078 18.8201L4.42078 19.6401L5.25078 16.6001L5.05078 16.2901C4.22853 14.977 3.79192 13.4593 3.79078 11.9101C3.79078 7.37006 7.49078 3.67006 12.0308 3.67006C14.2308 3.67006 16.3008 4.53006 17.8508 6.09006C18.6183 6.85402 19.2265 7.76272 19.6402 8.76348C20.0539 9.76425 20.2648 10.8372 20.2608 11.9201C20.2808 16.4601 16.5808 20.1501 12.0408 20.1501ZM16.5608 13.9901C16.3108 13.8701 15.0908 13.2701 14.8708 13.1801C14.6408 13.1001 14.4808 13.0601 14.3108 13.3001C14.1408 13.5501 13.6708 14.1101 13.5308 14.2701C13.3908 14.4401 13.2408 14.4601 12.9908 14.3301C12.7408 14.2101 11.9408 13.9401 11.0008 13.1001C10.2608 12.4401 9.77078 11.6301 9.62078 11.3801C9.48078 11.1301 9.60078 11.0001 9.73078 10.8701C9.84078 10.7601 9.98078 10.5801 10.1008 10.4401C10.2208 10.3001 10.2708 10.1901 10.3508 10.0301C10.4308 9.86006 10.3908 9.72006 10.3308 9.60006C10.2708 9.48006 9.77078 8.26006 9.57078 7.76006C9.37078 7.28006 9.16078 7.34006 9.01078 7.33006H8.53078C8.36078 7.33006 8.10078 7.39006 7.87078 7.64006C7.65078 7.89006 7.01078 8.49006 7.01078 9.71006C7.01078 10.9301 7.90078 12.1101 8.02078 12.2701C8.14078 12.4401 9.77078 14.9401 12.2508 16.0101C12.8408 16.2701 13.3008 16.4201 13.6608 16.5301C14.2508 16.7201 14.7908 16.6901 15.2208 16.6301C15.7008 16.5601 16.6908 16.0301 16.8908 15.4501C17.1008 14.8701 17.1008 14.3801 17.0308 14.2701C16.9608 14.1601 16.8108 14.1101 16.5608 13.9901Z"
                        fill="#FFFFFF" />
                </svg>
            </span>
            <p class="contact-header-text">WhatsApp 24/7:
                <a href="https://wa.me/16467403821" target="_blank" rel="noopener noreferrer"
                    rel="noopener noreferrer">+91 123-456-789</a>
            </p>
        </div>
    </div>
</div>
<nav class="navbar g-navbar navbar-expand-xl bg-white">
    <div class="container">
        <div class="brand-container">
            <a class="navbar-brand g-navbar-brand d-flex" routerLink="/">
                <img class="web-logo" src="/assets/images/common/holidaycom-horizontal-logo.png" alt="">
            </a>

            <!-- <a class="navbar-brand g-navbar-brand d-block d-xl-none" routerLink="/">
                <img class="mob-logo" src="assets/images/auth/gb-logo-small.png" alt="">
            </a> -->

            <div class="nav-item btn-get-started-container navbar-toggler p-0 border-0">
                <div class="usd-icon-tab">
                    $ USD
                </div>
                <a class="nav-link" *ngIf="!userDetails">
                    <button routerLink="auth/signup"
                        class="btn g-bg-primary btn-get-started g-color-white border-radius-100">Get Started</button>
                </a>

                <div class="dropdown nav-item user-icon-container navbar-toggler p-0 border-0"
                    [ngClass]="{'ms-auto': 'userDetails'}">
                    <button class="nav-link btn dropdown-toggle user-icon p-0" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <img *ngIf="userDetails" src="/assets/images/common/profile-placeholder.png" alt="profile icon">
                        <span *ngIf="!userDetails" class="navbar-toggler-icon"></span>
                    </button>
                    <ul class="dropdown-menu">
                        <ng-container *ngFor="let navItem of navigationData; let nIndex = index;">
                            <li *ngIf="navItem.name !== 'Privacy Policy' && navItem.name !== 'Terms & Conditions' && navItem.name !== 'Account Settings' && navItem.name !== 'My Plans' && navItem.name !== 'header.my_reward_points'"
                                class="me-0" [ngClass]="navItem.cssClass"><a
                                    [ngClass]="{'border-bottom-0' : !userDetails && navItem.name === 'Contact Us'}"
                                    class="dropdown-item py-2 cursor-pointer d-flex" [routerLink]="navItem.routeLink">
                                    {{ navItem.name }} <i class="g-chevron-right m-auto me-0"></i></a></li>
                            <li *ngIf="userDetails && navItem.name === 'My Plans'" class="me-0"
                                [ngClass]="navItem.cssClass"><a class="dropdown-item py-2 cursor-pointer d-flex"
                                    [routerLink]="navItem.routeLink"> {{ navItem.name }} <i
                                        class="g-chevron-right m-auto me-0"></i></a></li>
                            <!-- <li *ngIf="userDetails && navItem.name === 'header.my_reward_points'" class="me-0"
                                [ngClass]="navItem.cssClass"><a class="dropdown-item py-2 cursor-pointer d-flex"
                                    [routerLink]="navItem.routeLink"> {{ navItem.name}} <i
                                        class="g-chevron-right m-auto me-0"></i></a></li> -->
                            <li *ngIf="( userDetails || configData?.currencyConversionMasterEnabled ) && navItem.name === 'Account Settings'"
                                class="me-0" [ngClass]="navItem.cssClass"><a
                                    class="dropdown-item py-2 cursor-pointer d-flex"
                                    [routerLink]="navItem.routeLink + ( !userDetails ? '/currency' : '')"
                                    (click)=" navItem.name === 'Account Settings' ? updateSettingsRoute() : ''"> {{
                                    navItem.name}} <i class="g-chevron-right m-auto me-0"></i></a></li>
                            <li *ngIf="userDetails && navItem.name === 'Privacy Policy'" class="me-0"
                                [ngClass]="navItem.cssClass"><a class="dropdown-item py-2 cursor-pointer d-flex"
                                    [routerLink]="navItem.routeLink"> {{ navItem.name }} <i
                                        class="g-chevron-right m-auto me-0"></i></a></li>
                            <li *ngIf="userDetails && navItem.name === 'Terms & Conditions'" class="me-0"
                                [ngClass]="navItem.cssClass">
                                <a class="dropdown-item py-2 cursor-pointer" [routerLink]="navItem.routeLink">
                                    <div class="d-flex justify-content-start flex-wrap">
                                        <p class="m-0 border-bottom-0"> {{navItem.name.split('&')[0]}} </p>
                                        &nbsp;
                                        <p class="m-0 border-bottom-0">&amp; </p> &nbsp;
                                        <p class="m-0 border-bottom-0"> {{ navItem.name.split('&')[1]}}</p>
                                        <i class="g-chevron-right m-auto me-0"></i>
                                    </div>
                                </a>
                            </li>
                        </ng-container>
                        <li *ngIf="userDetails" class="me-0"><a
                                class="dropdown-item py-2 cursor-pointer d-flex logout-clas" (click)="logout()"> Log
                                Out<i class="g-chevron-right m-auto me-0"></i></a></li>
                    </ul>
                </div>
            </div>

        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a routerLink="/" class="nav-link active" aria-current="page">Home</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/choose-destination" class="nav-link">View Plans</a>
                </li>
                <li class="nav-item" *ngIf="userDetails">
                    <a routerLink="/my-plans" class="nav-link">My Plans</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/FAQ" class="nav-link">FAQ</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/contact-us" class="nav-link"> Contact Us</a>
                </li>
                <li class="nav-item usd-icon">
                    <a class="nav-link"> $ USD </a>
                </li>
                <!-- <li class="nav-item" *ngIf="userDetails || configData?.currencyConversionMasterEnabled">
                    <a [routerLink]="'/settings' +(userDetails ? '' : '/currency')" class="nav-link"
                        (click)="updateSettingsRoute()"> {{ 'header.settings' | translate }}</a>
                </li> -->
                <li class="nav-item" *ngIf="!userDetails">
                    <a class="nav-link">
                        <button routerLink="auth/signup" class="btn btn-get-started"> Get Started</button>
                    </a>
                </li>
                <li class="dropdown nav-item user-icon-container" *ngIf="userDetails">
                    <button class="nav-link btn dropdown-toggle user-icon p-0" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <img src="/assets/images/common/profile-placeholder.png" alt="profile icon">
                    </button>
                    <ul class="dropdown-menu">
                        <ng-container *ngFor="let navItem of navigationData; let nIndex = index;">
                            <li *ngIf="navItem.name !== 'Terms & Conditions'" class="me-0" [ngClass]="navItem.cssClass">
                                <a class="dropdown-item py-2 cursor-pointer d-flex" [routerLink]="navItem.routeLink"> {{
                                    navItem.name }} <i class="g-chevron-right m-auto me-0"></i></a>
                            </li>
                            <li *ngIf="navItem.name === 'Terms & Conditions'" class="me-0" [ngClass]="navItem.cssClass">
                                <a class="dropdown-item py-2 cursor-pointer" [routerLink]="navItem.routeLink">
                                    <div class="d-flex justify-content-start flex-wrap">
                                        <p class="m-0 border-bottom-0"> {{navItem.name.split('&')[0] }} </p>
                                        &nbsp;
                                        <p class="m-0 border-bottom-0">&amp; </p> &nbsp;
                                        <p class="m-0 border-bottom-0"> {{ navItem.name.split('&')[1] }}</p>
                                        <i class="g-chevron-right m-auto me-0"></i>
                                    </div>
                                </a>
                            </li>
                        </ng-container>
                        <li *ngIf="userDetails" class="me-0"><a class="dropdown-item py-2 cursor-pointer d-flex"
                                (click)="logout()"> Log Out <i class="g-chevron-right m-auto me-0"></i></a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
