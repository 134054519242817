import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountSettingsService {
  serverUrl = environment.serverUrl;
  customerId = environment.customerId;
  selectedSection$: BehaviorSubject<string> = new BehaviorSubject<string>('currency');

  constructor(private http: HttpClient) { }

  getSelectedSection() {
    return this.selectedSection$.asObservable();
  }
  setSelectedSection(val: string) {
    this.selectedSection$.next(val);
  }
}
