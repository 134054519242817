<div class="thank-you-shadow"></div>
<div class="thank-you-modal-parent ">
    <div class="thank-you-modal-body">
        <h4 class="text-center heading">Account deleted</h4>

        <ng-container>
            <p class="text-center body-text">Your account has been successfully deleted.
            </p>
        </ng-container>

        <div class="text-center thank-you-image-container">
            <img src="assets/images/help/thanks-email.png" class="thanks-img" alt="thanks-email">
        </div>

        <div class="d-flex align-items-center justify-content-center">
            <button type="submit" class="btn home-btn" (click)="close()">
                Return Home
            </button>
        </div>
    </div>
</div>