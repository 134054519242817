<div class="es-modal add-edit-modal">
    <div class="modal-header-flex">
        <div class="modal-header-text-container">
            <div class="mb-3 confirmation-icon">
                <svg *ngIf="title === 'Error' || title === 'Something went wrong!'" width="56" height="56"
                    viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
                    <mask id="mask0_2349_15915" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="16" y="16"
                        width="24" height="24">
                        <rect x="16" y="16" width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_2349_15915)">
                        <path
                            d="M17 37L28 18L39 37H17ZM20.45 35H35.55L28 22L20.45 35ZM28 34C28.2833 34 28.5208 33.9042 28.7125 33.7125C28.9042 33.5208 29 33.2833 29 33C29 32.7167 28.9042 32.4792 28.7125 32.2875C28.5208 32.0958 28.2833 32 28 32C27.7167 32 27.4792 32.0958 27.2875 32.2875C27.0958 32.4792 27 32.7167 27 33C27 33.2833 27.0958 33.5208 27.2875 33.7125C27.4792 33.9042 27.7167 34 28 34ZM27 31H29V26H27V31Z"
                            fill="#D92D20" />
                    </g>
                    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8" />
                </svg>

                <svg *ngIf="title === 'Successful!' || type === 'Success'" width="56" height="56" viewBox="0 0 56 56"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="48" height="48" rx="24" fill="#9FFFD1" />
                    <mask id="mask0_2349_15930" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="16" y="16"
                        width="24" height="24">
                        <rect x="16" y="16" width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_2349_15930)">
                        <path
                            d="M25.5496 34.0001L19.8496 28.3001L21.2746 26.8751L25.5496 31.1501L34.7246 21.9751L36.1496 23.4001L25.5496 34.0001Z"
                            fill="#20925B" />
                    </g>
                    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#2D9563" stroke-width="8" />
                </svg>

                <svg *ngIf="title === 'Info'" width="56" height="56" viewBox="0 0 56 56" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="48" height="48" rx="24" fill="#ADCEFF" />
                    <mask id="mask0_2483_16745" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="16" y="16"
                        width="24" height="24">
                        <rect x="16" y="16" width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_2483_16745)">
                        <path
                            d="M27 33H29V27H27V33ZM28 25C28.2833 25 28.5208 24.9042 28.7125 24.7125C28.9042 24.5208 29 24.2833 29 24C29 23.7167 28.9042 23.4792 28.7125 23.2875C28.5208 23.0958 28.2833 23 28 23C27.7167 23 27.4792 23.0958 27.2875 23.2875C27.0958 23.4792 27 23.7167 27 24C27 24.2833 27.0958 24.5208 27.2875 24.7125C27.4792 24.9042 27.7167 25 28 25ZM28 38C26.6167 38 25.3167 37.7375 24.1 37.2125C22.8833 36.6875 21.825 35.975 20.925 35.075C20.025 34.175 19.3125 33.1167 18.7875 31.9C18.2625 30.6833 18 29.3833 18 28C18 26.6167 18.2625 25.3167 18.7875 24.1C19.3125 22.8833 20.025 21.825 20.925 20.925C21.825 20.025 22.8833 19.3125 24.1 18.7875C25.3167 18.2625 26.6167 18 28 18C29.3833 18 30.6833 18.2625 31.9 18.7875C33.1167 19.3125 34.175 20.025 35.075 20.925C35.975 21.825 36.6875 22.8833 37.2125 24.1C37.7375 25.3167 38 26.6167 38 28C38 29.3833 37.7375 30.6833 37.2125 31.9C36.6875 33.1167 35.975 34.175 35.075 35.075C34.175 35.975 33.1167 36.6875 31.9 37.2125C30.6833 37.7375 29.3833 38 28 38ZM28 36C30.2333 36 32.125 35.225 33.675 33.675C35.225 32.125 36 30.2333 36 28C36 25.7667 35.225 23.875 33.675 22.325C32.125 20.775 30.2333 20 28 20C25.7667 20 23.875 20.775 22.325 22.325C20.775 23.875 20 25.7667 20 28C20 30.2333 20.775 32.125 22.325 33.675C23.875 35.225 25.7667 36 28 36Z"
                            fill="#428EFF" />
                    </g>
                    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#428EFF" stroke-width="8" />
                </svg>
                <svg *ngIf="title === 'Warning'" class="warning-svg-icon" width="56" height="56" viewBox="0 0 56 56"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="48" height="48" rx="24" fill="#ADCEFF" />
                    <mask id="mask0_2349_15915" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="16" y="16"
                        width="24" height="24">
                        <rect x="16" y="16" width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_2349_15915)">
                        <path
                            d="M17 37L28 18L39 37H17ZM20.45 35H35.55L28 22L20.45 35ZM28 34C28.2833 34 28.5208 33.9042 28.7125 33.7125C28.9042 33.5208 29 33.2833 29 33C29 32.7167 28.9042 32.4792 28.7125 32.2875C28.5208 32.0958 28.2833 32 28 32C27.7167 32 27.4792 32.0958 27.2875 32.2875C27.0958 32.4792 27 32.7167 27 33C27 33.2833 27.0958 33.5208 27.2875 33.7125C27.4792 33.9042 27.7167 34 28 34ZM27 31H29V26H27V31Z"
                            fill="#D92D20" />
                    </g>
                    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#428EFF" stroke-width="8" />
                </svg>
            </div>
        </div>
    </div>

    <div>
        <p class="modal-header-text mb-0">{{ title === 'Info' ? 'Warning!': title}}</p>
        <p class="modal-sub-header-text mb-0 mt-2" [innerHTML]="body"></p>
    </div>

    <div class="es-modal-footer d-flex justify-content-between mt-5 gap-2"
        *ngIf="(title === 'Error' || title === 'Something went wrong!' || title === 'Warning') && buttonGroup.length > 0; else showDefault;">
        <button type="button" *ngFor="let btn of buttonGroup" class="btn w-50"
            [ngClass]="{'btn-warning w-100': btn.title === 'Close', 'btn-danger btn-black': btn.title === 'Try Again', 'btn-secondary btn-back': btn.title === 'Back'}"
            (click)="close()"> {{ btn.title }}</button>
    </div>

    <ng-template #showDefault>
        <div class="es-modal-footer">
            <button type="button" class="btn mt-5 text-white w-100"
                [ngClass]="{'btn-success': (title === 'Successful!' || type === 'Success'), 'btn-primary': title === 'Info', 'btn-full-width': (title === 'Successful!' || title === 'Info' || type === 'Success') }"
                (click)="close()">{{ 'installation.close' | translate }}</button>
        </div>
    </ng-template>
</div>