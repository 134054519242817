import { Component, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogComponent } from 'src/app/global/service';
import { allNavbarActions } from 'src/app/global/state/user';

@Component({
  selector: 'app-delete-success',
  templateUrl: './delete-success.component.html',
  styleUrls: ['./delete-success.component.scss'],
})
export class DeleteSuccessComponent {
  dialogRef: any;

  constructor(private viewContainer: ViewContainerRef, private store: Store) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }
  close() {
    window.location.href = '/';
  }
}
