import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAlphabetOnly]'
})
export class AlphabetOnlyDirective {
  constructor(private control: NgControl) { }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const char = String.fromCharCode(event.which);
    if (!/^[a-zA-Z\s]*$/.test(char)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[appTrimSpaces]',
})
export class TrimSpacesDirective {
  @Output() ngModelChange = new EventEmitter();

  constructor(
    private el: ElementRef,
    private control: NgControl
  ) { }

  /**
   * Trim the input value on focus out of input component
   */
  @HostListener('focusout') onFocusOut() {
    (this.el.nativeElement as HTMLInputElement).value = (
      this.el.nativeElement as HTMLInputElement
    ).value.trim();
    this.ngModelChange.emit(this.el.nativeElement.value);
    this.control.control?.setValue(this.el.nativeElement.value);
  }
}
