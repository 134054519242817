export const environment = {
  production: false,
  gtmId: '',
  serverUrl: 'https://dev-api.esim.management.mobile.glowingbud.xyz',
  paymentServiceUrl: 'https://payment-microservice.glowingbud.xyz',
  customerId: '673711d2178aa369c1f7ab62',
  s3BucketAssets: 'https://assets.glowingbud.com',
  googleSocialAuth:
    '578470335082-g2nj4ga6lj601v7fg9k3588h564hubr2.apps.googleusercontent.com',
  // facebookSocialAuth: 459242780008685,
  facebookSocialAuth: 1240558107255344,
  appleSocialAuth: {
    // clientId: 'com.glowingbud.dev',
    clientId: 'dev.app.holiday',
    scope: 'name email',
    // redirectURI: 'https://dev-app.glowingbud.xyz/auth/signin',
    redirectURI: 'https://dev-app.holiday.glowingbud.xyz/auth/signin',
    usePopup: true,
    state: 'gbsocialsignup',
  },
  socialLoginAppleEnabled: true,
  socialLoginFacebookEnabled: true,
  socialLoginGoogleEnabled: true,
  universalURL:
    'https://esimsetup.apple.com/esim_qrcode_provisioning?carddata=',
  sp_key:
    'pk_test_51Molj3SCK3jr3By7jF6ShXIYV5iI5hUSXbFwxNhKzyAD60IaSCP6aLDynby4zbyjecVBr2yIyLkWjy3CeCbOeHjR00uLUmPG70',
  // sp_key: 'pk_test_51PjI8iRv7wYZZvE2GeOaUpsTWvtgqTElj2GjNoVIlH3KsDa5niCwLRYcqgUyLGsJZaekAv9ehkPGLg7eTT6qr7UT00ZBpCbKZC'
};
