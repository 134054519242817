import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

@Pipe({
	name: 'keys'
})
export class KeysPipe implements PipeTransform {
	transform(value: any): string[] {
		return Object.keys(value)
	}
}
