import { QRCodeModule } from 'angularx-qrcode';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { SearchCountryPipe, SearchFilterPipe } from './custom-pipe';
import {
  HeaderComponent,
  FooterComponent,
  PageTitleComponent,
  FaqComponent,
  LoaderComponent,
} from './component';
import { DialogService } from './service';
import { AlertComponent } from './dialog/alert/alert.component';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './state/user';
import { ActivationEffects } from './state/subscriptions';
import { CurrencyEffects } from './state/currency';
import { DraggableDirective } from './directive/draggable.directive';

import { LottieModule } from 'ngx-lottie';
import { DateDifferencePipe } from './custom-pipe/date-difference.pipe';
import { ImportantComponent } from './dialog/important/important.component';
import {
  InternetUsagePipe,
  DataInMbGbPipe,
  DataInGbPipe,
} from './custom-pipe/internet-usage.pipe';
import { PaymentStatusComponent } from './dialog/payment-status/payment-status.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipDirective } from './directive/tooltip.directive';
import { CurrencyComponent } from './dialog/currency/currency.component';
import { CurrencySymbolPipe } from './custom-pipe/currency-symbol.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { PageNotFoundComponent } from '../features/page-not-found/page-not-found.component';
import { LanguageAlertComponent } from './dialog/language-alert/language-alert.component';
import { TopUpComponent } from './dialog/top-up/top-up.component';
import { InstallationGuideComponent } from './component/installation-guide/installation-guide.component';
import { RemainingCharactersPipe } from './custom-pipe/remaining-characters.pipe';
import { DeleteAccountAlertComponent } from './dialog/delete-account-alert/delete-account-alert.component';
import { ConfirmDeleteAlertComponent } from './dialog/confirm-delete-alert/confirm-delete-alert.component';
import { AngularOtpLibModule } from '../../lib/angular-otp-box/lib/angular-otp-box.module';
import { AlphabetOnlyDirective } from './directive/alphabet-only.directive';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { CompatibleDevicesComponent } from '../features/compatible-devices/compatible-devices.component';
import { PreserveOrderPipe } from './custom-pipe/preserve-order.pipe';

export function playerFactory(): any {
  return import('lottie-web');
}
@NgModule({
  declarations: [
    SearchFilterPipe,
    HeaderComponent,
    FooterComponent,
    PageTitleComponent,
    // DialogComponent,
    AlertComponent,
    FaqComponent,
    DraggableDirective,
    LoaderComponent,
    DateDifferencePipe,
    ImportantComponent,
    InternetUsagePipe,
    PaymentStatusComponent,
    TooltipDirective,
    CurrencyComponent,
    CurrencySymbolPipe,
    PageNotFoundComponent,
    LanguageAlertComponent,
    TopUpComponent,
    DataInMbGbPipe,
    InstallationGuideComponent,
    RemainingCharactersPipe,
    DeleteAccountAlertComponent,
    ConfirmDeleteAlertComponent,
    AlphabetOnlyDirective,
    SearchCountryPipe,
    CompatibleDevicesComponent,
    PreserveOrderPipe,
    DataInGbPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([UserEffects, ActivationEffects, CurrencyEffects]),
    LottieModule.forRoot({ player: playerFactory }),
    TranslateModule,
    QRCodeModule,
    AngularOtpLibModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    MatRadioModule,
  ],
  exports: [
    SearchFilterPipe,
    HeaderComponent,
    FooterComponent,
    PageTitleComponent,
    // DialogComponent,
    AlertComponent,
    FaqComponent,
    DraggableDirective,
    LoaderComponent,
    DateDifferencePipe,
    InternetUsagePipe,
    DataInMbGbPipe,
    PaymentStatusComponent,
    TooltipDirective,
    CurrencyComponent,
    CurrencySymbolPipe,
    TranslateModule,
    TopUpComponent,
    RemainingCharactersPipe,
    AngularOtpLibModule,
    AlphabetOnlyDirective,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    MatRadioModule,
    SearchCountryPipe,
    PreserveOrderPipe,
    DataInGbPipe,
  ],
  providers: [CurrencyPipe],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [DialogService],
    };
  }
  constructor() {}
  // public resolveComponent(component: any) {
  //   return this.componentFactoryResolver.resolveComponentFactory(component);
  // }
}
