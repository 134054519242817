import { Component, ViewContainerRef } from '@angular/core';
import { DialogComponent } from '../../service';

@Component({
  selector: 'app-confirm-delete-alert',
  templateUrl: './confirm-delete-alert.component.html',
  styleUrls: ['./confirm-delete-alert.component.scss'],
})
export class ConfirmDeleteAlertComponent {
  dialogRef!: DialogComponent;

  constructor(private viewContainer: ViewContainerRef) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  close(flag: boolean): void {
    this.dialogRef.closeEvent.emit(flag);
  }
}
