import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {} from '../state/user';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  serverUrl = environment.serverUrl;
  customerId = environment.customerId;
  paymentServiceUrl = environment.paymentServiceUrl;
  contentHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

  private transactionHistory: BehaviorSubject<Array<any>> = new BehaviorSubject<
    Array<any>
  >([]);

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  getUserDetails() {
    return this.http.get(`${this.serverUrl}/subscribers/me`);
  }

  logout() {
    this.localStorageService.removeToken();
    this.localStorageService.removeItem('upcomingPlan');
    this.localStorageService.removeItem('refreshToken');
    this.localStorageService.removeItem('plan-data');
  }

  updateProfile(userData: any) {
    return this.http.put(`${this.serverUrl}/subscribers`, userData);
  }

  changePassword(userData: any) {
    return this.http.put(
      `${this.serverUrl}/subscribers/changePassword`,
      userData
    );
  }

  updateCurrency(currencyData: any) {
    return this.http.put(
      `${this.serverUrl}/subscribers/settings/currency`,
      currencyData
    );
  }

  getRewardPointsDetails() {
    return this.http.get(`${this.serverUrl}/subscriber/rewardPoints`);
  }

  // redeemPointsOrCouponValuation(
  //   planDetails: any,
  //   redeemPoints: number,
  //   couponCode?: string,
  //   country?: string
  // ) {
  //   let rewardPointObj;

  //   if (redeemPoints > 0) {
  //     rewardPointObj = {
  //       redeemPoints: redeemPoints,
  //       rewardPointsApplied: true,
  //     };
  //   }
  //   if (couponCode && couponCode.trim() != '') {
  //     rewardPointObj = {
  //       ...rewardPointObj,
  //       coupon: couponCode,
  //       couponCodeApplied: true,
  //       country,
  //     };
  //   }
  //   return this.http.post(
  //     `${this.paymentServiceUrl}/payments/redeem-points-coupon/${planDetails._id}`,
  //     rewardPointObj
  //   );
  // }

  redeemPointsOrCouponValuation(payload: any) {
    const { planId } = payload;

    // Make the API call
    return this.http.post(
      `${this.paymentServiceUrl}/payments/redeem-points-coupon/${planId}`,
      payload
    );
  }

  setTransactionHistory(transactions: Array<any>) {
    this.transactionHistory.next(transactions);
  }

  getTransactionsHistory(fromLocal = false) {
    if (fromLocal) {
      return this.transactionHistory.asObservable();
    } else {
      return this.http.get(
        `${this.paymentServiceUrl}/payments/loyalty-transactions`
      );
    }
  }

  deleteAccountOTP(reqBody: any) {
    return this.http.post(`${this.serverUrl}/subscriber/delete/otp`, reqBody);
  }

  verifyOTP(userData: any) {
    return this.http.post(
      `${this.serverUrl}/subscriber/delete/otpVerify`,
      userData
    );
  }

  getDevices(): Observable<any> {
    const apiAddress =
      environment.s3BucketAssets +
      `/${environment.customerId}/lang/eSimCompatibleDevices.json`;
    return this.http.get(apiAddress, { headers: this.contentHeader });
  }
}
